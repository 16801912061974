<template>
  <v-row>
    <v-col cols="12" lg="2" class="my-0 py-0">
      <v-list color="transparent" dense>
        <v-list-item class="align-center">
          <v-list-item-content>
            <v-list-item-subtitle v-text="$t('invoice.total.amount')" />
            <v-list-item-title v-text="sTotal" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>

    <v-col cols="12" :lg="wrongBalance ? 3 : 2" class="my-0 py-0">
      <v-list color="transparent" dense>
        <v-list-item class="align-center">
          <v-list-item-icon
            v-if="wrongBalance"
            class="orange--text align-center"
          >
            <icon-warning size="1.5em" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle v-text="$t('invoice.paid.amount')" />
            <v-list-item-title
              :class="{
                'red--text': fPaid < 0,
                'orange--text': fPaid !== fTotalRounded,
                'green--text': !wrongBalance,
              }"
              v-text="sPaid"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>

    <v-col cols="12" :lg="wrongBalance ? 3 : 2" class="my-0 py-0">
      <v-list color="transparent" dense>
        <v-list-item class="align-center">
          <v-list-item-icon
            v-if="wrongBalance"
            class="orange--text align-center"
          >
            <icon-warning size="1.5em" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle v-text="$t('invoice.balance.amount')" />
            <v-list-item-title
              :class="{
                'red--text': wrongBalance,
                'green--text': !wrongBalance,
              }"
              v-text="sBalance"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>

    <v-col v-if="wrongBalance" cols="12" class="ma-0 pa-0">
      <v-list color="transparent" dense>
        <v-alert dense text type="warning">
          {{ $t(`error.invoice.${lessBalance ? "less" : "high"}.balance`) }}
        </v-alert>
      </v-list>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";
import { positive } from "@/plugins/helpers";
import IconWarning from "@/components/icons/IconWarning.vue";

@Component({
  components: { IconWarning },
})
export default class InvoicePaymentMethodAmounts extends Mixins(InvoiceMixin) {
  get editable() {
    return !this.signed || this.isReceived;
  }

  get wrongBalance() {
    return this.editable && this.fBalance !== 0;
  }

  get lessBalance() {
    return this.editable && this.fBalance < 0;
  }

  get highBalance() {
    return this.editable && this.fBalance > 0;
  }

  get sPaid() {
    return this.getCurrencyFormat(positive(this.fPaid));
  }

  get sBalance() {
    return this.getCurrencyFormat(this.fBalance);
  }
}
</script>
