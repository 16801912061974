<template>
  <svg-icon :data="sData" class="pkg-feather" outlined v-bind="$attrs" />
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class IconWarning extends Vue {
  sData: string = require("@icon/feather/alert-triangle.svg");
}
</script>
