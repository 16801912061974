var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","lg":"2"}},[_c('v-list',{attrs:{"color":"transparent","dense":""}},[_c('v-list-item',{staticClass:"align-center"},[_c('v-list-item-content',[_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.$t('invoice.total.amount'))}}),_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.sTotal)}})],1)],1)],1)],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","lg":_vm.wrongBalance ? 3 : 2}},[_c('v-list',{attrs:{"color":"transparent","dense":""}},[_c('v-list-item',{staticClass:"align-center"},[(_vm.wrongBalance)?_c('v-list-item-icon',{staticClass:"orange--text align-center"},[_c('icon-warning',{attrs:{"size":"1.5em"}})],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.$t('invoice.paid.amount'))}}),_c('v-list-item-title',{class:{
              'red--text': _vm.fPaid < 0,
              'orange--text': _vm.fPaid !== _vm.fTotalRounded,
              'green--text': !_vm.wrongBalance,
            },domProps:{"textContent":_vm._s(_vm.sPaid)}})],1)],1)],1)],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","lg":_vm.wrongBalance ? 3 : 2}},[_c('v-list',{attrs:{"color":"transparent","dense":""}},[_c('v-list-item',{staticClass:"align-center"},[(_vm.wrongBalance)?_c('v-list-item-icon',{staticClass:"orange--text align-center"},[_c('icon-warning',{attrs:{"size":"1.5em"}})],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.$t('invoice.balance.amount'))}}),_c('v-list-item-title',{class:{
              'red--text': _vm.wrongBalance,
              'green--text': !_vm.wrongBalance,
            },domProps:{"textContent":_vm._s(_vm.sBalance)}})],1)],1)],1)],1),(_vm.wrongBalance)?_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-list',{attrs:{"color":"transparent","dense":""}},[_c('v-alert',{attrs:{"dense":"","text":"","type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t(`error.invoice.${_vm.lessBalance ? "less" : "high"}.balance`))+" ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }